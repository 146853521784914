// Your settings div/button
export const showMoreButton =
  " text-blue hover:cursor-pointer hover:text-orange-dark flex justify-between border-blue items-center pt-3 pb-4 group peer transition-all "
// Save or send information buttons
export const saveButton =
  " border-2 border-blue p-1 px-3 pt-1 pb-2 mr-8 mb-4 font-sans text-blue hover:text-white hover:bg-100 transition-all duration-100 bg-no-repeat to-blue from-blue bg-gradient-to-t "
// Delete changes or decline button
export const underlineButton =
  " relative font-sans text-blue after:absolute after:inset-0 after:border-b-2 after:border-current after:transition-transform hover:text-orange-dark pt-1 pb-2 after:-translate-y-1 hover:after:translate-y-0 "
//Chip buttons
export const chipButton =
  " border-2 border-gray p-1 px-3 pt-1 pb-2 font-sans text-blue hover:text-white hover:bg-100 hover:border-blue transition-all duration-100 bg-no-repeat to-blue from-blue bg-gradient-to-t "

//hoverDirectionStyles
export const right = " bg-x-0 bg-right"
export const left = " bg-x-0 bg-left"
export const down = " bg-y-0 bg-bottom"
export const up = " bg-y-0 bg-top"

import { useMsal } from "@azure/msal-react"
import axios from "axios"
import { navigate } from "gatsby"
import React, { useState, useEffect, useMemo, useRef } from "react"
import { useQueryClient, QueryClient, useMutation, useQuery } from "react-query"
import { authToken } from "../Security/authToken"

export const GetProfile = (account, inProgress, instance) => {
  return useQuery(
    "profile",
    async () => {
      const token = await authToken(account, inProgress, instance)
      //await new Promise(resolve => setTimeout(resolve, 500))
      if (token?.length > 0) {
        return axios
          .get(process.env.GATSBY_APP_WEBAPI + "HefContacts/me", {
            headers: {
              "Conent-Type": "application/json",
              // "Ocp-Apim-Subscription-Key":
              //   process.env.GATSBY_APP_SUBSCRIPTION_KEY,
              "Ocp-Apim-Subscription-Key":
                process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
              Authorization: "Bearer " + token,
            },
          })
          .then(res => {
            return res.data
          })
      } else {
        return null
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  )
}
export const GetSubscriptions = (account, inProgress, instance) =>
  useQuery(
    "getSubscription",
    async () => {
      //await new Promise(resolve => setTimeout(resolve, 500))
      return axios
        .get(
          process.env.GATSBY_APP_WEBAPI + "HefSubscription/MySubscriptions",
          {
            headers: {
              "Conent-Type": "application/json",
              "Ocp-Apim-Subscription-Key":
                process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
              Authorization:
                "Bearer " + (await authToken(account, inProgress, instance)),
            },
          }
        )
        .then(res => res.data)
    },
    {
      refetchOnWindowFocus: false,
    }
  )

export const GetMembership = (account, inProgress, instance) =>
  useQuery(
    "checkMembership",
    async () => {
      const token = await authToken(account, inProgress, instance)
      //await new Promise(resolve => setTimeout(resolve, 500))
      if (token?.length > 0) {
        return axios
          .get(process.env.GATSBY_APP_WEBAPI + "HefContacts/CheckMembership", {
            headers: {
              "Conent-Type": "application/json",
              "Ocp-Apim-Subscription-Key":
                process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
              Authorization:
                "Bearer " + (await authToken(account, inProgress, instance)),
            },
          })
          .then(res => res.data)
      }
    },
    { refetchOnWindowFocus: false }
  )

export const Gender = genderCode => {
  let gender = ""
  genderCode == 1
    ? (gender = "Mann")
    : genderCode == 2
    ? (gender = "Kvinne")
    : genderCode == 778380000
    ? (gender = "Ikke-kjønnet")
    : genderCode == 778380001
    ? (gender = "Kjønnsskeiv")
    : genderCode == 778380002
    ? (gender = "Ikke-binær")
    : (gender = "Ikke oppgitt")
  return gender
}
